import { Policy } from './policy';

export class GeneralPolicy implements Policy {
    // ALLOW
    static readonly ALLOW_ALL = new GeneralPolicy(true, 'Allow everyone to view this');
    static readonly ALLOW_OTHER_ORGS = new GeneralPolicy(true, 'Allow other organisations to view this');

    // DENY
    static readonly DENY_ALL = new GeneralPolicy(false, 'Deny everyone to view this');
    static readonly DENY_OTHER_ORGS = new GeneralPolicy(false, 'Deny other organisations to view this');
    static readonly DENY_ORG_USERS_DEPARTMENTS = new GeneralPolicy(
        false,
        'Deny organisation users / departments to view this',
    );

    readonly allow: boolean;

    readonly text: string;

    constructor(allow: boolean, text: string) {
        this.allow = allow;
        this.text = text;
    }

    static all() {
        return [
            this.ALLOW_ALL,
            this.ALLOW_OTHER_ORGS,
            this.DENY_ALL,
            this.DENY_OTHER_ORGS,
            this.DENY_ORG_USERS_DEPARTMENTS,
        ];
    }

    public toJSON() {
        return {
            conditions: [],
            actions: ['Retrieve'],
            effect: this.allow,
        };
    }
}
