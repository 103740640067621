import { useFeatureFlags, useQuery, useResult } from '@/app/composable';
import { UserRoles } from '@/app/constants';
import store from '@/app/store';
import { S } from '@/app/utilities';
import gql from 'graphql-tag';
import * as R from 'ramda';
import { FieldType } from '.';
import { ConditionValue } from '../models';
import { FeatureFlagType } from '@/app/types';

const { isEnabled: isFeatureEnabled } = useFeatureFlags();

export class Field {
    static readonly COUNTRY = new Field(
        'org.country',
        'Organisation country',
        FieldType.SELECT,
        'country',
        'countries',
        'from',
        'Aruba',
        `countries {
                id
                name
            }
        `,
        (results: { countries: [] }) => {
            const processedResults: any[] = [];
            R.sortWith([R.ascend(R.prop('name'))])(results.countries).forEach((res: any) => {
                processedResults.push(new ConditionValue(`${res.id}`, res.name));
            });
            return processedResults;
        },
    );

    static readonly CONTINENT = new Field(
        'org.continent',
        'Organisation continent',
        FieldType.SELECT,
        'continent',
        'continents',
        'from',
        'Europe',
        `continents {
                id
                name
            }
        `,
        (results: { continents: [] }) => {
            const processedResults: any[] = [];
            R.sortWith([R.ascend(R.prop('name'))])(results.continents).forEach((res: any) => {
                processedResults.push(new ConditionValue(`${res.id}`, res.name));
            });
            return processedResults;
        },
    );

    static readonly ORGANISATION_TYPE = new Field(
        'org.type',
        'Organisation type',
        FieldType.SELECT,
        'organisation type',
        'organisation types',
        'with',
        'generic',
        `organisationTypes {
                id,
                name
            }
        `,
        (results: { organisationTypes: [] }) => {
            const processedResults: any[] = [];
            results.organisationTypes.forEach((res: { id: string; name: string }) => {
                processedResults.push(new ConditionValue(res.id, res.name));
            });

            return processedResults;
        },
    );

    static readonly ORGANISATION_ID = new Field(
        'org.id',
        'Organisation ID',
        FieldType.INTEGER,
        'id',
        'ids',
        'with',
        'ID',
    );

    static readonly ORGANISATION_USERS = new Field(
        'org.users',
        'Organisation users',
        FieldType.SELECT,
        'organisation user',
        'organisation users',
        'with',
        '1',
        `organisation(id: ${store.state.auth.user?.organisationId}){
            users {
            id,
            firstName,
            lastName,
            email
            }
        }`,
        (results: { organisation: { users: [] } }) => {
            const processedResults: any[] = [];
            results.organisation.users.forEach(
                (res: { id: string; firstName: string; lastName: string; email: string }) => {
                    processedResults.push({
                        id: res.id,
                        label: `${res.firstName + ' ' + res.lastName}`,
                        email: res.email,
                    });
                },
            );

            return processedResults;
        },
    );

    static readonly ORGANISATION_LEGAL_NAME = new Field(
        'org.legalName',
        'Organisation legal name',
        FieldType.STRING,
        'legal name',
        'legal names',
        'with',
        'Legal name',
        '',
        null,
        undefined,
        [],
        128, // max length
    );

    static readonly ORGANISATION_BUSINESS_NAME = new Field(
        'org.businessName',
        'Organisation business name',
        FieldType.STRING,
        'business name',
        'business names',
        'with',
        'Business name',
        '',
        null,
        undefined,
        [],
        128, // max length
    );

    static readonly USER_EMAIL = new Field(
        'email',
        'User email',
        FieldType.EMAIL,
        'email',
        'emails',
        'with',
        'john.doe@example.org',
        '',
        null,
        undefined,
        [],
        320, // max length
    );

    static readonly USER_ROLE = new Field(
        'role',
        'User role',
        FieldType.SELECT,
        'role',
        'roles',
        'with',
        'role',
        '',
        null,
        undefined,
        [
            {
                id: UserRoles.Manager,
                name: S.capitalizeFirstLetter(UserRoles.Manager),
            },
            {
                id: UserRoles.Member,
                name: S.capitalizeFirstLetter(UserRoles.Member),
            },
        ],
    );

    static readonly USER_DEPARTMENT_ROLE = new Field(
        'department.role',
        'User department role',
        FieldType.SELECT,
        'department role',
        'department roles',
        'with',
        'department role',
        `organisationRoles(id: ${store.state.auth.user?.organisationId}) {
                id,
                name
            }
        `,
        (results: { organisationRoles: [] }) => {
            const processedResults: any[] = [];
            results.organisationRoles.forEach((res: { id: string; name: string }) => {
                processedResults.push(new ConditionValue(res.id, res.name));
            });

            return processedResults;
        },
    );

    static readonly DEPARTMENT_NAME = new Field(
        'department.id',
        'Department name',
        FieldType.SELECT,
        'department name',
        'department names',
        'with',
        'department name',
        `organisationDepartments(organisationId: ${store.state.auth.user?.organisationId}) {
                id,
                name
            }
        `,
        (results: { organisationDepartments: [] }) => {
            const processedResults: any[] = [];
            results.organisationDepartments.forEach((res: { id: string; name: string }) => {
                processedResults.push({ id: res.id, label: res.name });
            });

            return processedResults;
        },
    );

    static readonly DEPARTMENT_COUNTRY = new Field(
        'department.country',
        'Department country',
        FieldType.SELECT,
        'department country',
        'department countries',
        'from',
        'Aruba',
        `countries {
                id
                name
            }
        `,
        (results: { countries: [] }) => {
            const processedResults: any[] = [];
            R.sortWith([R.ascend(R.prop('name'))])(results.countries).forEach((res: any) => {
                processedResults.push(new ConditionValue(`${res.id}`, res.name));
            });
            return processedResults;
        },
    );

    readonly key: string;

    readonly fieldType: FieldType;

    readonly singularPolicyLabel: string;

    readonly pluralPolicyLabel: string;

    readonly prefix: string;

    readonly displayLabel: string;

    readonly sampleValue: string;

    readonly query: string | undefined;

    readonly resultsProcessing: any | undefined;

    readonly featureKey: FeatureFlagType | undefined;

    readonly maxLength: number | undefined;

    values: ConditionValue[] | undefined;

    valuesMap: any;

    private static firstTime = true;

    private static readonly SELECTIVE_SHARING_FIELDS = [
        Field.COUNTRY,
        Field.CONTINENT,
        Field.ORGANISATION_TYPE,
        Field.ORGANISATION_BUSINESS_NAME,
    ];

    private static readonly RESTRICTED_ACCESS_FIELDS = [
        // Field.USER_DEPARTMENT_ROLE,
        Field.DEPARTMENT_NAME,
        Field.ORGANISATION_USERS,
    ];

    constructor(
        key: string,
        displayLabel: string,
        fieldType: FieldType,
        singularPolicyLabel: string,
        pluralPolicyLabel: string,
        prefix: string,
        sampleValue: string,
        query?: string,
        resultsProcessing?: any,
        featureKey?: FeatureFlagType | undefined,
        values?: any,
        maxLength?: number,
    ) {
        this.key = key;
        this.fieldType = fieldType;
        this.singularPolicyLabel = singularPolicyLabel;
        this.pluralPolicyLabel = pluralPolicyLabel;
        this.prefix = prefix;
        this.displayLabel = displayLabel;
        this.sampleValue = sampleValue;
        this.query = query;
        this.resultsProcessing = resultsProcessing;
        this.valuesMap = {};
        this.featureKey = featureKey;
        this.values = values;
        this.maxLength = maxLength;
    }

    static all() {
        return this.getAvailableFields(this.SELECTIVE_SHARING_FIELDS.concat(this.RESTRICTED_ACCESS_FIELDS));
    }

    static selectiveSharingFields() {
        return this.getAvailableFields(this.SELECTIVE_SHARING_FIELDS);
    }

    static restrictedAccessFields() {
        return this.getAvailableFields(this.RESTRICTED_ACCESS_FIELDS);
    }

    static initialize(): Promise<void> {
        return new Promise((resolve, reject) => {
            const queries: string[] = [];
            Field.all().forEach((field: Field) => {
                if (field.query) {
                    queries.push(field.query);
                } else if (field.values) {
                    field.values = field.values.map((item: any) =>
                        item instanceof ConditionValue ? item : new ConditionValue(item.id, item.name),
                    );
                    field.updateValueMap();
                }
            });
            if (queries.length > 0) {
                const { onResult, onError, error, result } = useQuery(
                    gql`{
                        ${queries.join('\n')}
                    }`,
                    {},
                );
                onResult(() => {
                    const results = useResult(result, null, (data: any) => data);
                    const resultItems: any = results.value ? results.value : {};
                    for (let i = 0; i < Field.all().length; i++) {
                        const field = Field.all()[i];
                        if (field.query) {
                            field.values = field.resultsProcessing ? field.resultsProcessing(resultItems) : resultItems;
                            field.updateValueMap();
                        }
                    }
                    resolve();
                });
                onError(() => {
                    reject(new Error(`Unable to load field values: ${error}`));
                });
            } else {
                resolve();
            }
            Field.firstTime = false;
        });
    }

    static find(key: string): Field | null {
        for (let i = 0; i < Field.all().length; i++) {
            const field = Field.all()[i];
            if (field.key === key) {
                return field;
            }
        }

        return null;
    }

    isInteger(): boolean {
        return this.fieldType === FieldType.INTEGER;
    }

    isString(): boolean {
        return this.fieldType === FieldType.STRING || this.fieldType === FieldType.EMAIL;
    }

    isSelect(): boolean {
        return this.fieldType === FieldType.SELECT;
    }

    getValues(): Promise<ConditionValue[] | undefined> {
        return new Promise((resolve, reject) => {
            if (Field.firstTime) {
                this.fetchValues()
                    .then(() => {
                        resolve(this.values);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            } else {
                resolve(this.values);
            }
        });
    }

    getValue(rawIds: string[] | string | number[] | number): ConditionValue[] {
        let ids: string[] | string = [];
        if (!R.is(Array, rawIds)) {
            ids = this.isSelect() ? [`${rawIds}`] : `${rawIds}`;
        } else {
            const tempIds: string[] = [];
            (rawIds as []).forEach((id) => {
                tempIds.push(`${id}`);
            });
            ids = tempIds;
        }

        if (!this.isSelect()) {
            if (R.is(Array, ids)) {
                const result: ConditionValue[] = [];
                (ids as []).forEach((id) => {
                    result.push(new ConditionValue(id, id));
                });
                return result;
            }
            return [new ConditionValue(ids as string, ids as string)];
        }
        if (this.values && this.values.length > 0) {
            let result = [];
            for (let i = 0; i < this.values.length; i++) {
                const val: ConditionValue = this.values[i];
                if (ids.includes(val.id)) {
                    result.push(this.valuesMap[val.id]);
                }
                if (R.hasIn('children', val) && val.children && val.children.length > 0) {
                    let allChildrenIncluded = true;
                    const childrenValues = [];
                    for (let c = 0; c < val.children.length; c++) {
                        const child = val.children[c];
                        if (ids.includes(child.id)) {
                            childrenValues.push(this.valuesMap[`${val.id}`].childMap[child.id]);
                        } else {
                            allChildrenIncluded = false;
                        }
                    }
                    if (allChildrenIncluded) {
                        result.push(val);
                    } else {
                        result = [...result, ...childrenValues];
                    }
                }
            }
            return result;
        }
        return [];
    }

    findParentForChildId(id: string) {
        if (!this.values || this.values.length === 0) {
            return null;
        }
        for (let i = 0; i < this.values.length; i++) {
            const parent: ConditionValue = this.values[i];
            if (parent.children && parent.children.length > 0) {
                for (let c = 0; c < parent.children.length; c++) {
                    const child = parent.children[c];
                    if (child.id === id) {
                        return parent;
                    }
                }
            }
        }

        return null;
    }

    getSiblingsForId(id: string) {
        if (!this.values || this.values.length === 0) {
            return [];
        }
        for (let i = 0; i < this.values.length; i++) {
            const parent: ConditionValue = this.values[i];
            if (parent.id === id) {
                const result = [...this.values];
                result.splice(i, 1);
                return result;
            }
            if (parent.children && parent.children.length > 0) {
                for (let c = 0; c < parent.children.length; c++) {
                    const child = parent.children[c];
                    if (child.id === id) {
                        const result = [...parent.children];
                        result.splice(c, 1);
                        return result;
                    }
                }
            }
        }

        return [];
    }

    private fetchValues(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (Field.firstTime) {
                const queries: string[] = [];
                Field.all().forEach((field: Field) => {
                    if (field.query) {
                        queries.push(field.query);
                    }
                });
                const { onResult, onError, error, result } = useQuery(
                    gql`{
                        ${queries.join('\n')}
                    }`,
                    {},
                );
                onResult(() => {
                    const results = useResult(result, null, (data: any) => data);
                    const resultItems: any = results.value ? results.value : {};
                    for (let i = 0; i < Field.all().length; i++) {
                        const field = Field.all()[i];
                        if (field.query) {
                            field.values = field.resultsProcessing ? field.resultsProcessing(resultItems) : resultItems;
                        }
                    }

                    resolve();
                });
                onError(() => {
                    reject(new Error(`Unable to load field '${this.displayLabel}' with error: ${error.value}`));
                });
                Field.firstTime = false;
            } else {
                resolve();
            }
        });
    }

    private static getAvailableFields(fields: Field[]): Field[] {
        const availableFields = [];
        for (let i = 0; i < fields.length; i++) {
            const field = fields[i];
            if (R.isNil(field.featureKey) || isFeatureEnabled(field.featureKey)) {
                availableFields.push(field);
            }
        }

        return availableFields;
    }

    private updateValueMap(): Field {
        if (!this.values) return this;

        this.valuesMap = {};

        // process values to valuesMap
        this.values.forEach((item: any) => {
            const children: ConditionValue[] = [];
            if (item.children && item.children.length) {
                item.children.forEach((child: ConditionValue) => {
                    children.push(new ConditionValue(child.id, child.label));
                });
            }
            this.valuesMap[`${item.id}`] = new ConditionValue(`${item.id}`, item.label, children);
        });

        return this;
    }
}
