import { Field } from '../constants';
import { ExceptionPolicy, GeneralPolicy, Policy } from '../models';

export function useAccessControl(rawPolicies: any[], defaultPolicy: GeneralPolicy) {
    let generalPolicy: GeneralPolicy | null = null;
    let policies: ExceptionPolicy[] | null = null;

    const processPolicies = (
        policiesToProcess: any[],
    ): { generalPolicy: GeneralPolicy | null; policies: ExceptionPolicy[] } => {
        const tempPolicies: ExceptionPolicy[] = [];
        let tempGeneralPolicy: GeneralPolicy | null = null;

        policiesToProcess.forEach((policyObj) => {
            const policy: Policy = Policy.parse(policyObj);

            if (tempGeneralPolicy && policy instanceof GeneralPolicy)
                throw Error('More than one general policies found');

            if (policy instanceof GeneralPolicy) tempGeneralPolicy = policy as GeneralPolicy;
            else tempPolicies.push(policy as ExceptionPolicy);
        });

        if (tempGeneralPolicy === null) tempGeneralPolicy = defaultPolicy;

        return {
            generalPolicy: tempGeneralPolicy,
            policies: tempPolicies,
        };
    };

    const getGeneralPolicy = () => {
        return generalPolicy;
    };

    const getPolicies = () => {
        return policies;
    };

    /**
     * Initialize access control
     */
    const initialize = (): Promise<{ generalPolicy: GeneralPolicy | null; policies: ExceptionPolicy[] }> => {
        return new Promise<{ generalPolicy: GeneralPolicy | null; policies: ExceptionPolicy[] }>((resolve, reject) => {
            Field.initialize()
                .then(() => {
                    if (rawPolicies.length === 0) resolve({ generalPolicy: null, policies: [] });
                    const processedPolicies = processPolicies(rawPolicies);
                    policies = processedPolicies.policies;
                    generalPolicy = processedPolicies.generalPolicy;
                    if (generalPolicy) resolve({ generalPolicy, policies });
                    else reject(new Error('Missing general policy'));
                })
                .catch((e) => {
                    const errorMessage = e ? e.message : 'Error while initialising fields';
                    reject(new Error(errorMessage));
                });
        });
    };

    return { initialize, getGeneralPolicy, getPolicies };
}
