import * as R from 'ramda';
import { ExceptionPolicy, GeneralPolicy, IndividualCondition } from '.';
import { Field, Operant } from '../constants';
import { ConditionValue } from './condition-value';

export abstract class Policy {
    static parse(unprocessedPolicy: { object: any; effect: boolean; actions: string[]; conditions: any[] }): Policy {
        if (
            !R.hasIn('effect', unprocessedPolicy) ||
            !R.hasIn('actions', unprocessedPolicy) ||
            !R.hasIn('conditions', unprocessedPolicy)
        ) {
            throw Error(`Policy "${JSON.stringify(unprocessedPolicy)} is invalid."`);
        }

        return this.processConditions(unprocessedPolicy.conditions, unprocessedPolicy.effect);
    }

    private static processConditions(unprocessedConditions: any[], effect: boolean): Policy {
        const conditions: IndividualCondition[] = [];
        if (unprocessedConditions.length === 0 || R.isEmpty(unprocessedConditions[0])) {
            if (effect) {
                return GeneralPolicy.ALLOW_ALL;
            }

            return GeneralPolicy.DENY_ALL;
        }

        unprocessedConditions.forEach((unprocessedCondition: { field: string; operant: string; value: string[] }) => {
            const field: Field | null = Field.find(unprocessedCondition.field);
            if (!field) {
                throw new Error(`Field ${unprocessedCondition.field} not found in supported fields.`);
            }
            const operant: Operant | null = Operant.find(unprocessedCondition.operant);
            if (!operant) {
                throw new Error(`Operant ${unprocessedCondition.operant} not found in supported operants.`);
            }
            const value: ConditionValue[] = field.getValue(unprocessedCondition.value);
            if (!value || value.length === 0) {
                throw new Error(
                    `Value ${unprocessedCondition.value} not valid for field type '${field.fieldType.key}'.`,
                );
            }
            conditions.push(new IndividualCondition(field, operant, value));
        });

        return new ExceptionPolicy(effect, conditions);
    }
}
