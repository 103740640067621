


























































import { ConfirmButton } from '@/app/components';
import { computed } from '@vue/composition-api';
import { Field, Operant } from '../constants';
import AccessPolicyFieldSelect from './AccessPolicyFieldSelect.vue';
import AccessPolicyOperantSelect from './AccessPolicyOperantSelect.vue';
import AccessPolicyValueSelect from './AccessPolicyValueSelect.vue';

export default {
    name: 'AccessPolicyEditNode',
    props: {
        field: Field,
        operant: Operant,
        values: Array,
        identifier: Number,
        fields: Array,
        editMode: {
            type: Boolean,
            default: false,
        },
    },
    components: { AccessPolicyFieldSelect, AccessPolicyOperantSelect, AccessPolicyValueSelect, ConfirmButton },
    setup(props: any, { emit }: { emit: any }) {
        const newField = computed({
            get: () => {
                return props.field;
            },
            set: (updated) => {
                emit('updated-field', props.identifier, updated);
            },
        });

        const newOperant = computed({
            get: () => {
                return props.operant;
            },
            set: (updated) => {
                emit('updated-operant', props.identifier, updated);
            },
        });

        const newValues = computed({
            get: () => {
                return props.values;
            },
            set: (updated) => {
                emit('updated-value', props.identifier, updated);
            },
        });

        const deleteCondition = () => {
            emit('delete');
        };

        return { newField, newOperant, newValues, deleteCondition };
    },
};
