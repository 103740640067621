import { IndividualCondition } from './individual-condition';
import { Policy } from './policy';

export class ExceptionPolicy implements Policy {
    allow: boolean;

    conditions: IndividualCondition[];

    constructor(allow: boolean, conditions: IndividualCondition[]) {
        this.allow = allow;
        this.conditions = conditions;
    }

    public toJSON() {
        const conditionsJSON: any[] = [];
        this.conditions.forEach((condition: IndividualCondition) => {
            conditionsJSON.push(condition.toJSON());
        });
        return {
            conditions: conditionsJSON,
            actions: ['Retrieve'],
            effect: this.allow,
        };
    }
}
