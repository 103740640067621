import * as R from 'ramda';
import { Field, FieldType, Operant } from '../constants';
import { ConditionValue } from './condition-value';

export class IndividualCondition {
    readonly field: Field;

    readonly operant: Operant;

    readonly fieldValues: ConditionValue[];

    constructor(field: Field, operant: Operant, values: ConditionValue[]) {
        this.field = field;
        this.operant = operant;
        this.fieldValues = values;
    }

    public isValid(): boolean {
        return this.field != null && this.operant != null && this.fieldValues != null && this.fieldValues.length > 0;
    }

    public toString(): string {
        let quote = '';
        if (this.field.fieldType.valueShowQuotes) {
            quote = '"';
        }
        const valueStrings = this.fieldValues ? R.map((val) => `${quote}${val.label}${quote}`, this.fieldValues) : [];
        const lastValueString = valueStrings.pop();
        let valueString = lastValueString;
        if (valueStrings.length > 0) {
            valueString = `${valueStrings.join(', ')} or ${lastValueString}`;
        }
        return `${this.field.prefix} ${this.field.singularPolicyLabel} <span class="font-bold">${this.operant.policyLabel}</span> <span class="font-bold break-all">${valueString}</span>`;
    }

    public toJSON() {
        let values: any = null;
        if (this.fieldValues.length === 1) {
            values = this.fieldValues[0].getIds();
        } else if (this.fieldValues.length > 1) {
            values = [];
            this.fieldValues.forEach((val: ConditionValue) => {
                const ids = val.getIds();
                if (R.is(Array, ids)) {
                    values = [...values, ...ids];
                } else {
                    values.push(ids);
                }
            });
        }
        return {
            field: this.field.key,
            operant: this.operant.key,
            value: R.is(Array, values) ? [...new Set(values)] : values,
        };
    }
}
