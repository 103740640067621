




































import * as R from 'ramda';
import { computed, ref, watch } from '@vue/composition-api';
import Treeselect from '@riophae/vue-treeselect';
import { Field, FieldType, Operant } from '../constants';
import { ConditionValue } from '../models';
import { ValidationProvider } from 'vee-validate';

export default {
    name: 'AccessPolicyValueSelect',
    model: {
        prop: 'selection',
        event: 'update-selection',
    },
    props: {
        selection: Array,
        field: {
            type: Field,
            required: true,
        },
        operant: {
            type: Operant,
        },
    },
    components: { Treeselect, ValidationProvider },
    setup(props: any, { emit }: { emit: any }) {
        const currentField = ref(props.field);
        const selectedValue = ref(props.selection && props.selection.length > 0 ? props.selection[0].label : []);
        const selectedValues = ref(props.selection ? R.map((sel) => sel.id, props.selection) : []);
        const values = ref([]);

        const updateSelection = () => {
            if (R.isEmpty(selectedValue.value)) {
                emit('update-selection', []);
            } else {
                emit('update-selection', [new ConditionValue(selectedValue.value, selectedValue.value)]);
            }
        };

        watch(
            () => props.field,
            (changedField) => {
                if (changedField.fieldType === FieldType.SELECT) {
                    changedField.getValues().then((result: []) => {
                        values.value = result;
                    });
                }
                if (currentField.value.key !== changedField.key) {
                    selectedValue.value = [];
                    selectedValues.value = [];
                    currentField.value = changedField;
                    updateSelection();
                }
            },
            { immediate: true },
        );

        watch(
            () => props.selection,
            (selection) => {
                selectedValue.value = selection && selection.length > 0 ? selection[0].label : [];
                selectedValues.value = selection ? R.map((sel) => sel.id, selection) : [];
            },
            { immediate: true },
        );

        const onTreeSelect = (selection: any) => {
            selectedValues.value = !props.selection ? [selection] : [...props.selection, selection];
            emit('update-selection', selectedValues.value);
        };

        const onTreeDeselect = (selection: any) => {
            const elementIndex = props.selection.findIndex((sel: any) => sel.id === selection.id);
            if (elementIndex >= 0) {
                const newSelection = [...props.selection];
                newSelection.splice(elementIndex, 1);
                emit('update-selection', newSelection);
            }
            const parent = props.field.findParentForChildId(selection.id);
            if (parent) {
                const parentIndex = props.selection.findIndex((sel: any) => sel.id === parent.id);
                if (parentIndex >= 0) {
                    const newSelection = [...props.selection];
                    newSelection.splice(parentIndex, 1);

                    emit('update-selection', [...newSelection, ...props.field.getSiblingsForId(selection.id)]);
                }
            }
        };

        const validationRules = computed(() => {
            let config: any = { required: true, regex: /^[^+*?^$()[\]{}|\\]+$/ };

            if ([FieldType.STRING, FieldType.EMAIL].includes(props.field.fieldType)) {
                config = { ...config, min: 2 };

                if (
                    props.field.fieldType === FieldType.EMAIL &&
                    [Operant.EQUALS, Operant.NOT_EQUALS].includes(props.operant)
                ) {
                    config = { ...config, email: true, min: 7 };
                }
            }

            return config;
        });

        return {
            selectedValue,
            selectedValues,
            updateSelection,
            FieldType,
            values,
            onTreeSelect,
            onTreeDeselect,
            Operant,
            validationRules,
        };
    },
};
