import { Operant } from './operant';

export class FieldType {
    static readonly SELECT = new FieldType('SELECT', [Operant.EQUALS, Operant.NOT_EQUALS]);

    private static STRING_OPERATORS = [
        Operant.EQUALS,
        Operant.NOT_EQUALS,
        Operant.CONTAINS,
        Operant.NOT_CONTAINS,
        Operant.STARTS_WITH,
        // TODO: should there be a NOT_STARTS_WITH ?
        Operant.ENDS_WITH,
    ];

    static readonly STRING = new FieldType('STRING', FieldType.STRING_OPERATORS, true);

    static readonly EMAIL = new FieldType('EMAIL', FieldType.STRING_OPERATORS, true);

    static readonly INTEGER = new FieldType('INTEGER', [
        Operant.EQUALS,
        Operant.NOT_EQUALS,
        Operant.GREATER_THAN,
        Operant.GREATER_THAN_EQUALS,
        Operant.LESS_THAN,
        Operant.LESS_THAN_EQUALS,
    ]);

    readonly key: string;

    readonly operants: Operant[];

    readonly valueShowQuotes: boolean;

    constructor(key: string, operants: Operant[], valueShowQuotes = false) {
        this.key = key;
        this.operants = operants;
        this.valueShowQuotes = valueShowQuotes;
    }

    static all(): FieldType[] {
        return [this.SELECT, this.STRING, this.INTEGER];
    }
}
