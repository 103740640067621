import { useAxios } from '@/app/composable';
import { computed, Ref, ref } from '@vue/composition-api';
import { AuthzAPI } from '../api';
import { AuthzEntityType, AuthzRelation, AuthzResourceType } from '../constants';
import { AuthzPolicyRelation } from '../interfaces';

export default function useAuthz(resourceType: AuthzEntityType, resourceId: Ref<string | number>) {
    const { exec } = useAxios(true);

    const loading: Ref<boolean> = ref(false);
    const error: Ref<string | undefined> = ref();
    const policies: Ref<AuthzPolicyRelation[]> = ref([]);

    const policiesToAdd: Ref<AuthzPolicyRelation[]> = ref([]);
    const policiesToRemove: Ref<AuthzPolicyRelation[]> = ref([]);

    const hasChanges: Ref<boolean> = computed(
        () => policiesToAdd.value.length > 0 || policiesToRemove.value.length > 0,
    );

    const addPolicy = (subjectType: AuthzResourceType, subjectId: string) => {
        const newPolicy = { subjectType, subjectId, relation: AuthzRelation.Editor };
        if (subjectType === AuthzResourceType.Department) newPolicy['subjectRelation'] = 'member';
        // only add the policy if it's not already part of the policies
        // or the policies to be added
        if (
            !policies.value.some((policy: AuthzPolicyRelation) => arePoliciesEqual(policy, newPolicy)) &&
            !policiesToAdd.value.some((policy: AuthzPolicyRelation) => arePoliciesEqual(policy, newPolicy))
        )
            policiesToAdd.value.push(newPolicy);

        // if policy is in the list to be removed then take it out
        policiesToRemove.value = policiesToRemove.value.reduce(
            (acc: AuthzPolicyRelation[], policy: AuthzPolicyRelation) => {
                if (!arePoliciesEqual(policy, newPolicy)) acc.push(policy);
                return acc;
            },
            [],
        );
    };

    const removePolicy = (subjectType: AuthzResourceType, subjectId: string) => {
        const policyToRemove = { subjectType, subjectId, relation: AuthzRelation.Editor };
        if (subjectType === AuthzResourceType.Department) policyToRemove['subjectRelation'] = 'member';
        // only remove the policy if it's not already part of the policies
        // or the policies to be removed
        if (
            policies.value.some((policy: AuthzPolicyRelation) => arePoliciesEqual(policy, policyToRemove)) &&
            !policiesToRemove.value.some((policy: AuthzPolicyRelation) => arePoliciesEqual(policy, policyToRemove))
        )
            policiesToRemove.value.push(policyToRemove);

        // if policy is in the list to be added then take it out
        policiesToAdd.value = policiesToAdd.value.reduce((acc: AuthzPolicyRelation[], policy: AuthzPolicyRelation) => {
            if (!arePoliciesEqual(policy, policyToRemove)) acc.push(policy);
            return acc;
        }, []);
    };

    const policyChanges: Ref<{ add: AuthzPolicyRelation[]; remove: AuthzPolicyRelation[] }> = computed(() => {
        return {
            add: policiesToAdd.value,
            remove: policiesToRemove.value,
        };
    });

    const reset = () => {
        policiesToAdd.value = [];
        policiesToRemove.value = [];
    };

    const refetch = () => {
        if (resourceId.value) {
            error.value = undefined;
            loading.value = true;
            exec(AuthzAPI.getPolicies(resourceType, String(resourceId.value)))
                .then((res: any) => {
                    policies.value = res.data;
                })
                .catch((e: Error) => (error.value = e.message))
                .finally(() => (loading.value = false));
        }
    };

    function arePoliciesEqual(policy1: AuthzPolicyRelation, policy2: AuthzPolicyRelation): boolean {
        return (
            policy1.subjectId === policy2.subjectId &&
            policy1.subjectType === policy2.subjectType &&
            policy1.relation === policy2.relation
        );
    }

    refetch();

    return {
        policies,
        policyChanges,
        loading,
        hasChanges,
        addPolicy,
        removePolicy,
        reset,
        refetch,
    };
}
