




















































































































































































































































































































import { computed, defineComponent, PropType, ref } from '@vue/composition-api';
import * as R from 'ramda';
import Draggable from 'vuedraggable';
import { useAccessControl } from '../composable';
import { AccessLevel } from '../constants/access-levels.constants';
import { ExceptionPolicy, GeneralPolicy } from '../models';
import AccessPolicyEdit from './AccessPolicyEdit.vue';
import AccessPolicyNode from './AccessPolicyNode.vue';
import { Field } from '../constants';
import useAuthz from '../composable/authz';
import { AuthzResourceType } from '../constants/authz-resource-type.constant';
import RestrictedAccessPolicy from './RestrictedAccessPolicy.vue';

export default defineComponent({
    name: 'AccessPolicy',
    model: {
        prop: 'accessControl',
        event: 'update-access-control',
    },
    props: {
        element: {
            type: String,
            required: true,
        },
        identifier: {
            type: [String, Number],
        },
        policies: {
            type: Array,
            required: true,
        },
        readonly: {
            type: Boolean,
            default: true,
        },
        organisationId: {
            type: Number,
            default: null,
        },
        creatorIds: {
            type: Array as PropType<number[]>,
            required: true,
        },
        accessLevel: {
            type: String,
        },
    },
    components: {
        AccessPolicyNode,
        Draggable,
        AccessPolicyEdit,
        RestrictedAccessPolicy,
    },
    setup(props: any, { emit }: { emit: any }) {
        const elementId = computed(() => props.identifier);
        const loading = ref(true);
        const { addPolicy: addNewPolicy, removePolicy, policyChanges, policies: elementPolicies } = useAuthz(
            props.element,
            elementId,
        );
        const error = ref(null);
        const showAddPolicy = ref(false);
        const policyInEditMode = ref<number | null>(null);
        const generalPolicy = ref<GeneralPolicy | null>(null);
        const processedPolicies = ref<ExceptionPolicy[]>([]);
        const pendingChangePolicy = ref(false);
        const defaultPolicy = ref<GeneralPolicy>(
            props.accessLevel === AccessLevel.SelectiveSharing &&
                props.policies.find(
                    (policy: any) => policy.conditions.length && policy.conditions[0].field === 'org.id',
                )?.effect
                ? GeneralPolicy.ALLOW_ALL
                : GeneralPolicy.DENY_ALL,
        );

        const fields = computed(() =>
            props.accessLevel && props.accessLevel === AccessLevel.Restricted
                ? Field.restrictedAccessFields()
                : Field.selectiveSharingFields(),
        );

        const updatePolicies = () => {
            emit('update-access-control', {
                generalPolicy: generalPolicy.value,
                policies: policyChanges.value,
            });
        };

        const filteredPolicies = computed(() => {
            let policies = R.clone(props.policies);
            // if access level is selective sharing, remove own organisation exception
            if (props.accessLevel === AccessLevel.SelectiveSharing) {
                policies = policies.filter(
                    (policy: any) =>
                        policy.conditions.length === 0 ||
                        (policy.conditions.length && policy.conditions[0].field !== 'org.id'),
                );
            }

            return policies;
        });

        useAccessControl(filteredPolicies.value, defaultPolicy.value)
            .initialize()
            .then((result: { generalPolicy: GeneralPolicy | null; policies: ExceptionPolicy[] }) => {
                generalPolicy.value = result.generalPolicy === null ? defaultPolicy.value : result.generalPolicy;
                processedPolicies.value = result.policies;
                emit('update-access-control', {
                    generalPolicy: generalPolicy.value,
                    policies: processedPolicies.value,
                });
            })
            .catch((e) => {
                error.value = e;
                throw e;
            })
            .finally(() => {
                loading.value = false;
            });

        const defaultPolicyEffect = computed(() => generalPolicy.value && !generalPolicy.value.allow);

        const policyMoved = (event: any) => {
            const { oldIndex, newIndex } = event;
            if (oldIndex < newIndex) {
                processedPolicies.value = R.move(oldIndex, newIndex - 1, processedPolicies.value);
            } else if (oldIndex > newIndex) {
                processedPolicies.value = R.move(oldIndex, newIndex + 1, processedPolicies.value);
            }
        };

        const editPolicyException = (policyToEditIndex: number) => {
            policyInEditMode.value = policyToEditIndex;
        };

        const deletePolicyException = (policyToDeleteIndex: number) => {
            processedPolicies.value.splice(policyToDeleteIndex, 1);
        };

        const addPolicy = (policy: ExceptionPolicy) => {
            processedPolicies.value.push(policy);
            showAddPolicy.value = false;
        };

        const changeGeneralPolicy = (policy: GeneralPolicy) => {
            if (pendingChangePolicy.value) {
                pendingChangePolicy.value = false;
                generalPolicy.value = policy;
                for (let i = 0; i < processedPolicies.value.length; i++) {
                    processedPolicies.value[i].allow = !policy.allow;
                }

                emit('update-access-control', {
                    generalPolicy: generalPolicy.value,
                    policies: processedPolicies.value,
                });
            } else {
                pendingChangePolicy.value = true;
                setTimeout(() => {
                    pendingChangePolicy.value = false;
                }, 10000);
            }
        };

        return {
            error,
            generalPolicy,
            processedPolicies,
            defaultPolicyEffect,
            showAddPolicy,
            policyInEditMode,
            GeneralPolicy,
            policyMoved,
            addPolicy,
            editPolicyException,
            deletePolicyException,
            changeGeneralPolicy,
            pendingChangePolicy,
            AccessLevel,
            fields,
            AuthzResourceType,
            elementPolicies,
            loading,
            updatePolicies,
            addNewPolicy,
            removePolicy,
        };
    },
});
