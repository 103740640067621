












import { computed } from '@vue/composition-api';
import { Field } from '../constants';
import { ValidationProvider } from 'vee-validate';

export default {
    name: 'AccessPolicyFieldSelect',
    model: {
        prop: 'selection',
        event: 'update-selection',
    },
    props: {
        selection: Field,
        fields: {
            type: Array,
            required: true,
        },
        editMode: {
            type: Boolean,
            default: false,
        },
    },
    components: { ValidationProvider },
    setup(props: any, { emit }: { emit: any }) {
        const key = computed({
            get: () => {
                return props.selection ? props.selection.key : null;
            },
            set: (val) => {
                emit('update-selection', Field.find(val));
            },
        });

        return { key };
    },
};
