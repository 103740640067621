














import { computed, defineComponent } from '@vue/composition-api';

const sizeClasses: any = (wide: boolean) => ({
    xs: `${wide ? 'px-5' : 'px-2.5'} py-1 text-xs`,
    sm: `${wide ? 'px-6' : 'px-3'} py-1.5 text-sm leading-4`,
    md: `${wide ? 'px-8' : 'px-4'} py-2 text-sm`,
    lg: `${wide ? 'px-8' : 'px-4'} py-2 text-base`,
    xl: `${wide ? 'px-10' : 'px-6'} py-3 text-base`,
});

const colorClasses: any = {
    primary: {
        normal: 'border-transparent text-white bg-primary-600 hover:bg-primary-600',
        inverted: 'border-transparent text-primary-700 bg-primary-100 hover:bg-primary-200',
        outline: 'border-primary-500 text-primary-700 bg-transparent hover:bg-primary-100',
        link: 'border-transparent text-primary-700 bg-transparent hover:bg-primary-100',
    },
    secondary: {
        normal: 'border-transparent text-white bg-secondary-600 hover:bg-secondary-700',
        inverted: 'border-transparent text-secondary-700 bg-secondary-100 hover:bg-secondary-200',
        outline: 'border-secondary-500 text-secondary-700 bg-transparent hover:bg-secondary-100',
        link: 'border-transparent text-secondary-700 bg-transparent hover:bg-secondary-100',
    },
    neutral: {
        normal: 'border-transparent text-white bg-neutral-600 hover:bg-neutral-700',
        inverted: 'border-transparent text-neutral-700 bg-neutral-100 hover:bg-neutral-200',
        outline: 'border-neutral-300 text-neutral-600 bg-transparent hover:bg-neutral-100',
        link: 'border-transparent text-neutral-700 bg-transparent hover:bg-neutral-100',
    },
    info: {
        normal: 'border-transparent text-white bg-info-600 hover:bg-info-700',
        inverted: 'border-transparent text-info-700 bg-info-100 hover:bg-info-200',
        outline: 'border-info-500 text-info-700 bg-transparent hover:bg-info-100',
        link: 'border-transparent text-info-700 bg-transparent hover:bg-info-100',
    },
    danger: {
        normal: 'border-transparent text-white bg-danger-600 hover:bg-danger-700',
        inverted: 'border-transparent text-danger-700 bg-danger-100 hover:bg-danger-200',
        outline: 'border-danger-500 text-danger-700 bg-transparent hover:bg-danger-100',
        link: 'border-transparent text-danger-700 bg-transparent hover:bg-danger-100',
    },
    warn: {
        normal: 'border-transparent text-white bg-warn-600 hover:bg-warn-700',
        inverted: 'border-transparent text-warn-700 bg-warn-100 hover:bg-warn-200',
        outline: 'border-warn-500 text-warn-700 bg-transparent hover:bg-warn-100',
        link: 'border-transparent text-warn-700 bg-transparent hover:bg-warn-100',
    },
    success: {
        normal: 'border-transparent text-white bg-success-800 hover:bg-success-700',
        inverted: 'border-transparent text-success-800 bg-success-100 hover:bg-success-200',
        outline: 'border-success-500 text-success-800 bg-transparent hover:bg-success-100',
        link: 'border-transparent text-success-800 bg-transparent hover:bg-success-100',
    },
};

const wideMargins: any = { xs: '-ml-2.5', sm: '-ml-3', md: '-ml-4', lg: '-ml-4', xl: '-ml-6' };

export default defineComponent({
    name: 'TButton',
    props: {
        color: {
            type: String,
            default: 'primary',
            validator: (value: string): boolean =>
                ['primary', 'secondary', 'neutral', 'danger', 'info', 'success', 'warn'].includes(value),
        },
        type: {
            type: String,
            default: 'normal',
            validator: (value: string): boolean => ['normal', 'inverted', 'outline', 'link'].includes(value),
        },
        size: {
            type: String,
            default: 'md',
            validator: (value: string): boolean => ['xs', 'sm', 'md', 'lg', 'xl'].includes(value),
        },
        rounded: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        wide: {
            type: Boolean,
            default: false,
        },
        shadow: {
            type: Boolean,
            default: false,
        },
    },
    setup(props: any) {
        const classes = computed<string[]>(() => [
            'inline-flex items-center font-medium border focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:opacity-50 disabled:pointer-events-none',
            colorClasses[props.color][props.type],
            sizeClasses(props.wide)[props.size],
            props.shadow ? 'shadow-sm' : '',
            props.rounded ? 'rounded-full' : 'rounded-md',
            props.loading || props.disabled ? 'opacity-50 pointer-events-none' : '',
        ]);

        const spinnerClases = computed<string[]>(() => [
            ['xs', 'sm', 'md'].includes(props.size) ? 'w-4 h-4 mr-3' : 'w-5 h-5 mr-4',
            props.wide ? wideMargins[props.size] : '',
            props.type === 'normal' ? 'text-white' : `text-${props.color}-700`,
            'animate-spin',
        ]);

        return { classes, spinnerClases };
    },
});
