import * as R from 'ramda';

export class ConditionValue {
    readonly id: string;

    readonly label: string;

    readonly children?: ConditionValue[];

    readonly childMap: any;

    constructor(id: string, label: string, children?: ConditionValue[]) {
        this.id = id;
        this.label = label;
        this.children = children;
        this.childMap = {};
        if (this.children) {
            this.children.forEach((child: ConditionValue) => {
                this.childMap[child.id] = child;
            });
        }
    }

    getIds() {
        if (this.children && this.children.length > 0) {
            return R.map((child) => {
                return child.id;
            }, this.children);
        }
        return this.id;
    }
}
