















































import {
    computed,
    defineComponent,
    onBeforeUnmount,
    onUnmounted,
    PropType,
    ref,
    Ref,
    watch,
} from '@vue/composition-api';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default defineComponent({
    name: 'TFileDropzone',
    props: {
        id: {
            type: String,
            default: 'dropzone',
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        acceptedFiles: {
            type: Array as PropType<string[]>,
        },
        showDropzone: {
            type: Boolean,
            default: true,
        },
        forceClearFiles: {
            type: Date,
        },
    },
    components: {
        VueDropzone: vue2Dropzone,
    },
    setup(props, { emit }) {
        const isUnderDestruction = ref<boolean>(false);
        const tFileDropzoneRef: Ref<any> = ref<any>();
        const filesText = computed(() => {
            return props.multiple ? 'files' : 'a file';
        });

        const acceptedFilesOption = computed(() =>
            props.acceptedFiles ? props.acceptedFiles.map((fileType: string) => `.${fileType}`).join(',') : '.*',
        );

        const dropzoneOptions = computed(() => {
            return {
                url: 'https://suite5.eu',
                method: 'post',
                thumbnailWidth: 150,
                thumbnailHeight: 150,
                addRemoveLinks: true,
                maxFiles: props.multiple ? null : 1, // Doesn't change if you choose "other".
                uploadMultiple: false,
                maxFilesize: null,
                autoProcessQueue: false,
                autoQueue: true,
                acceptedFiles: acceptedFilesOption.value,
                createImageThumbnails: false,
                parallelUploads: 2,
                params: {},
                previewTemplate: `<div class="flex items-center py-2 text-sm text-left space-x-2">
                <div class="flex flex-2 items-center flex-grow space-x-2 text-primary-900">
                    <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-4 h-4">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"></path>
                    </svg>
                    <div class="flex-grow text-primary-700"><span data-dz-name></span></div>
                    <div class="flex-none w-24 text-right"><span data-dz-size></span></div>
                </div>
            </div>
        `,
                dictRemoveFile:
                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width="1.2em" height="1.2rem"><path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>',
            };
        });

        const onMaxFilesExceeded = (file: File) => {
            if (isUnderDestruction.value) return;
            emit('max-files-exceeded');
            tFileDropzoneRef.value.removeFile(file);
        };

        const onFilesChanged = () => {
            if (isUnderDestruction.value) return;
            // Concatenating the already accepted files and the newly added file before sending them via event
            const files = tFileDropzoneRef.value.getAcceptedFiles().concat(tFileDropzoneRef.value.getAddedFiles());
            emit('files-added', files);
        };

        const queuedFiles = () => tFileDropzoneRef.value.getQueuedFiles();

        const clear = () => {
            if (isUnderDestruction.value) return;
            emit('clear');
            tFileDropzoneRef.value.removeAllFiles();
        };

        watch(
            () => props.forceClearFiles,
            () => {
                tFileDropzoneRef.value.removeAllFiles();
            },
        );

        onBeforeUnmount(() => (isUnderDestruction.value = true));
        onUnmounted(() => (isUnderDestruction.value = false));

        return {
            filesText,
            dropzoneOptions,
            tFileDropzoneRef,
            queuedFiles,
            onMaxFilesExceeded,
            onFilesChanged,
            clear,
        };
    },
});
