
























import { computed, defineComponent } from '@vue/composition-api';
import Scrollbar from '@/app/components/Scrollbar.vue';

export default defineComponent({
    name: 'TPanel',
    props: {
        title: {
            type: String,
            required: false,
        },
        divided: {
            type: Boolean,
            default: false,
        },
        scrollable: {
            type: Boolean,
            default: false,
        },
        titleClass: {
            type: String,
            default: 'text-primary-700',
            validator: (value: string): boolean => value.startsWith('text-'),
        },
        paddingClass: {
            type: String,
            default: 'p-4',
            validator: (value: string): boolean => value.startsWith('p-'),
        },
    },
    setup(props, { slots }) {
        const hasActions = computed<boolean>(() => !!slots.actions);
        const hasFooter = computed<boolean>(() => !!slots.footer);
        const hasHeader = computed<boolean>(() => !!slots.header);

        const bodyComponent = computed(() => (props.scrollable ? Scrollbar : 'div'));

        return { bodyComponent, hasActions, hasFooter, hasHeader };
    },
});
