









import { ExceptionPolicy } from '../models';
import { S } from '@/app/utilities';

export default {
    name: 'AccessPolicyPreview',
    props: {
        policy: {
            type: ExceptionPolicy,
            required: true,
        },
    },
    setup() {
        return { S };
    },
};
