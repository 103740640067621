import { AxiosRequestConfig } from 'axios';

const endpoint = '/api/authz';

export default {
    getPolicies: (resourceType: string, resourceId: string) =>
        ({
            method: 'GET',
            url: `${endpoint}/policies/${resourceType}/${resourceId}?relation=editor`,
        } as AxiosRequestConfig),
};
