export enum AuthzResourceType {
    User = 'user',
    Organisation = 'organisation',
    Department = 'department',
    Task = 'task',
    Step = 'step',
    Asset = 'asset',
    Query = 'query',
    File = 'file',
    Schedule = 'schedule',
    Visualisation = 'visualisation',
    DataCheckinJob = 'job',
    DataCheckinPipeline = 'datacheckin',
    AnalyticsPipeline = 'analytics',
}
