
















































































































































































import { computed, PropType, ref, watch } from '@vue/composition-api';
import { GeneralPolicy } from '../models';
import { S } from '@/app/utilities';
import { Field } from '../constants';
import { AuthzResourceType } from '../constants/authz-resource-type.constant';
import { AdvancedSelect, ConfirmButton, ButtonGroup } from '@/app/components';
import {
    TrashIcon,
    ExclamationCircleIcon,
    UserIcon,
    OfficeBuildingIcon,
    ChevronDownIcon,
    ChevronRightIcon,
} from '@vue-hero-icons/outline';

export default {
    name: 'RestrictedAccessPolicy',
    model: {
        prop: 'policies',
        event: 'update-policies',
    },
    props: {
        element: {
            type: String,
            required: true,
        },
        readonly: {
            type: Boolean,
            default: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        policies: {
            type: Array,
            required: true,
        },
        creatorIds: {
            type: Array as PropType<number[]>,
            required: true,
        },
    },
    components: {
        AdvancedSelect,
        ConfirmButton,
        TrashIcon,
        ExclamationCircleIcon,
        ButtonGroup,
        UserIcon,
        OfficeBuildingIcon,
        ChevronDownIcon,
        ChevronRightIcon,
    },
    setup(props: any, { emit }: { emit: any }) {
        const openRestrictedAccessSummary = ref(true);
        const activeFilter = ref<string>('Users');
        const selectedUsers: any = ref([]);
        const selectedDepartments: any = ref([]);
        const error = ref(null);
        const generalPolicy = ref<GeneralPolicy>(GeneralPolicy.DENY_ALL);

        const fields = computed(() => Field.restrictedAccessFields());

        const restrictedAccessFields = computed(() => {
            const users = fields.value.find((field: any) => field.key === 'org.users');
            const departments = fields.value.find((field: any) => field.key === 'department.id');
            return {
                users: users ? users.values?.filter((user: any) => !props.creatorIds.includes(parseInt(user.id))) : [], // filter out the creators
                departments: departments ? departments.values : [],
            };
        });

        const remainingOfRestrictedAccessFields = computed(() => {
            const restOfFields: any = {
                users: [],
                departments: [],
            };
            const restOfUsers: any = restrictedAccessFields.value?.users?.map((uf: any) => {
                return { ...uf, name: `${uf.label} ${uf.email}` };
            });
            if (!selectedUsers.value.length) restOfFields.users = restOfUsers;
            else restOfFields.users = restOfUsers?.filter((user: any) => !selectedUsers.value.includes(user.id));

            if (!selectedDepartments.value.length) restOfFields.departments = restrictedAccessFields.value.departments;
            else
                restOfFields.departments = restrictedAccessFields.value.departments?.filter(
                    (department: any) => !selectedDepartments.value.includes(department.id),
                );

            return restOfFields;
        });

        const elementLabel = computed(() => {
            if (props.element === AuthzResourceType.AnalyticsPipeline) return 'analytics pipeline';
            if (props.element === AuthzResourceType.DataCheckinJob) return 'data check-in pipeline';

            return AuthzResourceType.Asset;
        });

        const addPolicy = (type: AuthzResourceType, resourceId: string) => {
            emit('add-policy', type, resourceId);
            emit('update-policies');
        };

        const removeSelectedPolicy = (type: AuthzResourceType, resourceId: string) => {
            if (type === AuthzResourceType.User)
                selectedUsers.value = selectedUsers.value.filter((selectedUser: any) => selectedUser !== resourceId);
            else if (type === AuthzResourceType.Department)
                selectedDepartments.value = selectedDepartments.value.filter(
                    (selectedDepartment: any) => selectedDepartment !== resourceId,
                );
            emit('remove-policy', type, resourceId);
            emit('update-policies');
        };

        const changeFilter = (filter: string) => {
            activeFilter.value = filter;
        };
        const restrictedAccessSummary = computed(() => {
            if (!selectedUsers.value.length && !selectedDepartments.value.length) return [];
            const selectedUsersList: any = [];
            const selectedDepartmentsList: any = [];
            restrictedAccessFields.value.departments?.forEach((df: { id: string; label: string }) => {
                if (selectedDepartments.value.includes(df.id)) {
                    selectedDepartmentsList.push({ id: df.id, label: df.label, type: AuthzResourceType.Department });
                }
            });
            restrictedAccessFields.value.users?.forEach((uf: any) => {
                if (selectedUsers.value.includes(uf.id)) {
                    selectedUsersList.push({
                        id: uf.id,
                        label: uf.label,
                        type: AuthzResourceType.User,
                        email: uf.email,
                    });
                }
            });
            return [...selectedDepartmentsList, ...selectedUsersList];
        });
        const getUsersText = (user: { id: string; label: string; email: string }) => {
            return `<div class="flex items-center space-x-2"><span>${S.sanitizeHtml(
                user.label,
            )}</span> <span class="text-xs">(${S.sanitizeHtml(user.email)})</span></div>`;
        };

        watch(
            () => props.policies,
            (policies: any) => {
                if (policies.length) {
                    // existing policies
                    const filteredUserPolicies = policies
                        .filter((policy: any) => policy.subjectType === AuthzResourceType.User)
                        .map((user: any) => user.subjectId);
                    const filteredDepartmentPolicies = policies
                        .filter((policy: any) => policy.subjectType === AuthzResourceType.Department)
                        .map((dep: any) => dep.subjectId);

                    selectedUsers.value = Array.from(new Set([...selectedUsers.value, ...filteredUserPolicies]));
                    selectedDepartments.value = Array.from(
                        new Set([...selectedDepartments.value, ...filteredDepartmentPolicies]),
                    );
                }
            },
            { immediate: true },
        );

        return {
            error,
            generalPolicy,
            GeneralPolicy,
            selectedUsers,
            selectedDepartments,
            fields,
            removeSelectedPolicy,
            activeFilter,
            changeFilter,
            restrictedAccessSummary,
            getUsersText,
            remainingOfRestrictedAccessFields,
            addPolicy,
            AuthzResourceType,
            openRestrictedAccessSummary,
            elementLabel,
            S,
        };
    },
};
